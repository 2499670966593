import React, { useState, useEffect } from 'react';
import ChooseCrewCard from '../../components/Cards/ChooseCrewCard';
import StartNewMissionCard from '../../components/Cards/StartNewMissionCard';
import { Alert } from '@material-ui/lab';
import Loading from '../../components/common/Loading';
import Axios from 'axios';

const NewMission = ({ lastMissionData, refreshModule }) => {
    const [startCrew1, setStartCrew1] = useState(null);
    const [startCrew2, setStartCrew2] = useState(null);
    const [startCrew3, setStartCrew3] = useState(null);
    const [startCrew4, setStartCrew4] = useState(null);
    const [startLoc, setStartLoc] = useState(lastMissionData.stopLoc || '');
    const [startEngine, setStartEngine] = useState(
        lastMissionData.stopEngine || ''
    );
    const [startMissionCat, setStartMissionCat] = useState();
    const [error, setError] = useState();
    const [warning, setWarning] = useState();
    const [loading, setLoading] = useState(false);

    const [crewList, setCrewList] = useState([]);
    const [missionCatList, setMissionCatList] = useState([]);

    useEffect(() => {
        setLoading(true);
        Axios.get('v1/crew')
            .then((res) => {
                setCrewList(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError('Feil under lasting av mannskapsliste....');
                setLoading(false);
            });
    }, []);
    useEffect(() => {
        setLoading(true);
        Axios.get('v1/missioncat')
            .then((res) => {
                setMissionCatList(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError('Feil under lasting av oppdragskategoriliste...');
                setLoading(false);
            });
    }, []);
    useEffect(() => {
        let warningMsg = null;
        if (!startMissionCat) {
            warningMsg = 'Mangler oppdragstype...';
        }
        if (!startEngine) {
            warningMsg = 'Mangler motortimer start...';
        }
        if (lastMissionData.stopEngine) {
            if (startEngine < lastMissionData.stopEngine) {
                warningMsg =
                    'Motortimer kan ikke være mindre enn ' +
                    lastMissionData.stopEngine;
            }
        }
        if (!startLoc) {
            warningMsg = 'Mangler oppstartssted...';
        }
        if (!startCrew1) {
            warningMsg = 'Mangler båtfører...';
        }

        if (warningMsg) {
            setWarning(warningMsg);
        } else {
            setWarning(null);
        }
    }, [
        lastMissionData.stopEngine,
        startMissionCat,
        startEngine,
        startLoc,
        startCrew1,
    ]);

    const handleStartSubmission = (event) => {
        if (event) {
            event.preventDefault();
        }
        let startData = {};
        if (startCrew1) {
            startData.crew1id = startCrew1.id;
            startData.crew1name = startCrew1.fullName;
        }
        if (startCrew2) {
            startData.crew2id = startCrew2.id;
            startData.crew2name = startCrew2.fullName;
        }
        if (startCrew3) {
            startData.crew3id = startCrew3.id;
            startData.crew3name = startCrew3.fullName;
        }
        if (startCrew4) {
            startData.crew4id = startCrew4.id;
            startData.crew4name = startCrew4.fullName;
        }
        startData.missioncatid = startMissionCat.id;
        startData.missioncatname = startMissionCat.name;
        startData.starttime = Date.now();
        startData.startengine = startEngine;
        startData.startloc = startLoc;
        Axios.post('v1/mission', startData)
            .then((res) => {
                refreshModule();
            })
            .catch((err) => {
                console.log(err);
                refreshModule();
                setError('Kunne ikke starte nytt oppdrag...');
            });
    };
    return (
        <React.Fragment>
            {loading && <Loading />}
            {error && (
                <Alert
                    severity='warning'
                    onClose={() => {
                        setError(null);
                    }}
                >
                    {error}
                </Alert>
            )}
            <ChooseCrewCard
                startCrew1={startCrew1}
                startCrew2={startCrew2}
                startCrew3={startCrew3}
                startCrew4={startCrew4}
                setStartCrew1={setStartCrew1}
                setStartCrew2={setStartCrew2}
                setStartCrew3={setStartCrew3}
                setStartCrew4={setStartCrew4}
                crewList={crewList}
                warning={warning}
            />
            <StartNewMissionCard
                startLoc={startLoc}
                setStartLoc={setStartLoc}
                startEngine={startEngine}
                setStartEngine={setStartEngine}
                missionCatList={missionCatList}
                setStartMissionCat={setStartMissionCat}
                handleStartSubmission={handleStartSubmission}
                warning={warning}
            />
        </React.Fragment>
    );
};

export default NewMission;
