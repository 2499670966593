import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from '@material-ui/core';
import {
    DirectionsBoat as DirectionsBoatIcon,
    DateRange as DateRangeIcon,
    Person as PersonIcon,
} from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import rclImage from '../../assets/images/rcl-nla.png';
import LogoutButton from '../../components/LogoutButton';
import moment from 'moment';

const HomeStatusCard = ({ lastMissionData, error, setError }) => {
    let missionStatusText;
    if (lastMissionData) {
        if (lastMissionData.id && !lastMissionData.stopTime) {
            missionStatusText = (
                <React.Fragment>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DirectionsBoatIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'RC Lillesand er for øyeblikket ute på ' +
                                    lastMissionData.missionCatName
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Oppdraget ble påbegynt ' +
                                    moment(lastMissionData.startTime).format(
                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Båtfører er ' + lastMissionData.crew1Name
                                }
                            />
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        } else {
            missionStatusText = (
                <React.Fragment>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DirectionsBoatIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'RC Lillesand er for øyeblikket ledig ved ' +
                                    lastMissionData.stopLoc
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Siste oppdrag ble avsluttet ' +
                                    moment(lastMissionData.stopTime).format(
                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    'Båtfører siste oppdrag var ' +
                                    lastMissionData.crew1Name
                                }
                            />
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <Card style={{ marginTop: 20 }}>
                <CardMedia component='img' image={rclImage} width='100%' />
                <CardHeader title='Velkommen til RC Lillesand' />
                <CardContent>
                    {error && (
                        <Alert
                            severity='warning'
                            onClose={() => {
                                setError();
                            }}
                        >
                            <AlertTitle>Wooopsie....</AlertTitle>
                            {error}
                        </Alert>
                    )}
                    <Typography variant='body1' component='p'>
                        Dette er en webapp for oppdragshåndtering for RC
                        Lillesand.
                    </Typography>
                    {missionStatusText}
                    <LogoutButton />
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default HomeStatusCard;
