import React from 'react';
import { Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const ChooseCrewCard = ({
    startCrew1,
    startCrew2,
    startCrew3,
    startCrew4,
    setStartCrew1,
    setStartCrew2,
    setStartCrew3,
    setStartCrew4,
    crewList,
    warning,
}) => {
    return (
        <Card style={{ marginTop: 20 }}>
            <CardHeader title='Velg Mannskap' />
            <CardContent>
                <Autocomplete
                    id='startCrew1'
                    options={crewList.filter((crew) => {
                        return (
                            crew.crewLevel > 3 &&
                            startCrew1 !== crew &&
                            startCrew2 !== crew &&
                            startCrew3 !== crew &&
                            startCrew4 !== crew
                        );
                    })}
                    noOptionsText='Ingen treff'
                    onChange={(e, val) => setStartCrew1(val)}
                    getOptionLabel={(option) => option.fullName}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Båtfører'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Autocomplete
                    id='startCrew2'
                    options={crewList.filter((crew) => {
                        return (
                            crew.crewLevel > 2 &&
                            startCrew1 !== crew &&
                            startCrew2 !== crew &&
                            startCrew3 !== crew &&
                            startCrew4 !== crew
                        );
                    })}
                    noOptionsText='Ingen treff'
                    getOptionLabel={(option) => option.fullName}
                    onChange={(e, val) => setStartCrew2(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Navigatør'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Autocomplete
                    id='startCrew3'
                    options={crewList.filter((crew) => {
                        return (
                            crew.crewLevel > 1 &&
                            startCrew1 !== crew &&
                            startCrew2 !== crew &&
                            startCrew3 !== crew &&
                            startCrew4 !== crew
                        );
                    })}
                    noOptionsText='Ingen treff'
                    getOptionLabel={(option) => option.fullName}
                    onChange={(e, val) => setStartCrew3(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Førstehjelper'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <Autocomplete
                    id='startCrew4'
                    options={crewList.filter((crew) => {
                        return (
                            startCrew1 !== crew &&
                            startCrew2 !== crew &&
                            startCrew3 !== crew &&
                            startCrew4 !== crew
                        );
                    })}
                    noOptionsText='Ingen treff'
                    getOptionLabel={(option) => option.fullName}
                    onChange={(e, val) => setStartCrew4(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Mannskap'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
            </CardContent>
        </Card>
    );
};

export default ChooseCrewCard;
