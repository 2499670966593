import React, { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import NewMission from './NewMission';
import CurrentMission from './CurrentMission';
import Loading from '../../components/common/Loading';
import Axios from 'axios';

const Mission = () => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(true);
    const [lastMissionData, setLastMissionData] = useState(() => {
        if (localStorage.getItem('lastMission')) {
            return JSON.parse(atob(localStorage.getItem('lastMission')));
        } else {
            return {};
        }
    });

    useEffect(() => {
        if (shouldRefresh) {
            setLoading(true);
            Axios.get('v1/lastmission')
                .then((response) => {
                    setLoading(false);
                    localStorage.setItem(
                        'lastMission',
                        btoa(JSON.stringify(response.data[0]))
                    );
                    setLastMissionData(response.data[0]);
                    setShouldRefresh(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setError(
                        'Kunne ikke laste data fra server. Lokale data blir brukt hvis tilgjengelig...'
                    );
                    if (localStorage.getItem('lastMission')) {
                        const localMissionData =
                            JSON.parse(
                                atob(localStorage.getItem('lastMission'))
                            ) || null;
                        setLastMissionData(localMissionData);
                        setShouldRefresh(false);
                    } else {
                        setLastMissionData({ stopTime: true });
                        setShouldRefresh(false);
                    }
                });
        }
    }, [shouldRefresh]);
    const handleRefreshCommand = () => {
        setShouldRefresh(true);
    };
    return (
        <React.Fragment>
            {loading && <Loading />}
            {error && (
                <Alert
                    severity='warning'
                    onClose={() => {
                        setError(null);
                    }}
                >
                    {error}
                </Alert>
            )}
            {!lastMissionData.stopTime && (
                <CurrentMission
                    lastMissionData={lastMissionData}
                    refreshModule={handleRefreshCommand}
                />
            )}
            {(lastMissionData.stopTime || !lastMissionData) && (
                <NewMission
                    lastMissionData={lastMissionData}
                    refreshModule={handleRefreshCommand}
                />
            )}
        </React.Fragment>
    );
};

export default Mission;
