import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';

const NewStatusDialog = ({
    statusList,
    newStatusDialogOpen,
    handleNewStatusDialogToggle,
    handleStatusChange,
}) => {
    return (
        <Dialog
            id='statusDialog'
            open={newStatusDialogOpen}
            onClose={handleNewStatusDialogToggle}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Status</DialogTitle>
            <DialogContent>
                {statusList.map((status) => (
                    <Button
                        key={status.id}
                        variant='contained'
                        color='primary'
                        data-status={status.name}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        size='large'
                        onClick={handleStatusChange}
                    >
                        {status.name}
                    </Button>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default NewStatusDialog;
