import React, { useState, useEffect, useCallback } from 'react';
import { Alert } from '@material-ui/lab';
import MissionDataExp from '../../components/Expansion/MissionDataExp';
import MissionLogExp from '../../components/Expansion/MissionLog';
import NewStatusDialog from '../../components/Dialogs/NewStatusDialog';
import NewLogEntryDialog from '../../components/Dialogs/NewLogEntryDialog';
import EndMissionDialog from '../../components/Dialogs/EndMissionDialog';

import Axios from 'axios';

const CurrentMission = ({ lastMissionData, refreshModule }) => {
    const [error, setError] = useState();
    const [dialogError, setDialogError] = useState();
    const [logEntry, setLogEntry] = useState();
    const [logEntryType, setLogEntryType] = useState();
    const [stopLoc, setStopLoc] = useState('');
    const [stopEngine, setStopEngine] = useState('');
    const [stopDistance, setStopDistance] = useState('');

    const [newStatusDialogOpen, setNewStatusDialogOpen] = useState(false);
    const [newLogEntryDialogOpen, setNewLogEntryDialogOpen] = useState(false);
    const [endMissionDialogOpen, setEndMissionDialogOpen] = useState(false);

    const [logTypeList, setLogTypeList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [logEntries, setLogEntries] = useState([]);

    const handleEndMissionDialogToggle = () => {
        setEndMissionDialogOpen(!endMissionDialogOpen);
    };
    const handleNewLogEntryDialogToggle = () => {
        setNewLogEntryDialogOpen(!newLogEntryDialogOpen);
    };
    const handleNewStatusDialogToggle = () => {
        setNewStatusDialogOpen(!newStatusDialogOpen);
    };
    const handleStatusChange = (evt) => {
        handleSaveLogEntry(
            lastMissionData.id,
            'STATUS',
            evt.currentTarget.getAttribute('data-status')
        );
        setNewStatusDialogOpen(!newStatusDialogOpen);
    };
    const handleNewLogEntry = () => {
        handleSaveLogEntry(lastMissionData.id, logEntryType.name, logEntry);
        setNewLogEntryDialogOpen(!newLogEntryDialogOpen);
    };
    const handleEndMission = () => {
        if (stopLoc !== '' && stopEngine !== '' && stopDistance !== '') {
            console.log('yaaa');
            if (stopEngine >= lastMissionData.startEngine) {
                const stopTime = Date.now();
                const inputData = {
                    stoploc: stopLoc,
                    stopengine: stopEngine,
                    stoptime: stopTime,
                    distance: stopDistance,
                };

                Axios.patch(`v1/mission/${lastMissionData.id}`, inputData)
                    .then((res) => {
                        localStorage.removeItem('missionLog');
                        localStorage.removeItem('lastMission');
                        setEndMissionDialogOpen(false);
                        refreshModule();
                    })
                    .catch((err) => {
                        console.log(err);
                        setDialogError(
                            'Avslutning av oppdrag gikk ikke. Prøv på nytt senere...'
                        );
                    });
            }
        } else {
            console.log('nooo');
            setDialogError('Sørg for at alle felt er fyllt ut...');
        }
    };
    const handleSaveLogEntry = (missionid, cat, remark) => {
        const logData = {
            missionid: missionid,
            category: cat,
            remark: remark,
            timestamp: Date.now(),
        };
        Axios.post('v1/missionlog', logData)
            .then((res) => {
                fetchLogEntries();
            })
            .catch((err) => {
                console.log(err);
                //setOfflineLog([...offlineLog, logData]);
                setError('Lagring feilet...Prøver på nytt senere.');
            });
    };
    const fecthStatusList = useCallback(() => {
        Axios.get('v1/status')
            .then((res) => {
                setStatusList(res.data);
                localStorage.setItem(
                    'statuslist',
                    JSON.stringify(btoa(res.data))
                );
            })
            .catch((err) => {
                setError(
                    'Klarte ikke å hente statuslister. Bruker lokale data hvis tilgjengelig...'
                );
                if (localStorage.getItem('statuslist')) {
                    setStatusList(
                        JSON.parse(atob(localStorage.getItem('statuslist')))
                    );
                }
            });
    }, []);
    const fetchLogTypeList = useCallback(() => {
        Axios.get('v1/logtype')
            .then((res) => {
                setLogTypeList(res.data);
            })
            .catch((err) => {
                setError('Klarte ikke å hente Logtypelister.');
            });
    }, []);
    const fetchLogEntries = useCallback(() => {
        Axios.get(`v1/missionlog/${lastMissionData.id}`)
            .then((res) => {
                localStorage.setItem(
                    'missionLog',
                    btoa(JSON.stringify(res.data))
                );
                setLogEntries(res.data);
            })
            .catch((err) => {
                console.log(err);
                if (localStorage.getItem('missionLog')) {
                    let currentLog = JSON.parse(
                        atob(localStorage.getItem('missionLog'))
                    );
                    setLogEntries(currentLog);
                }
            });
    }, [lastMissionData.id]);
    useEffect(() => {
        fecthStatusList();
        fetchLogTypeList();
    }, [fecthStatusList, fetchLogTypeList]);
    useEffect(() => {
        fetchLogEntries();
    }, [fetchLogEntries]);
    return (
        <React.Fragment>
            <EndMissionDialog
                endMissionDialogOpen={endMissionDialogOpen}
                handleEndMissionDialogToggle={handleEndMissionDialogToggle}
                handleEndMission={handleEndMission}
                stopLoc={stopLoc}
                setStopLoc={setStopLoc}
                stopEngine={stopEngine}
                setStopEngine={setStopEngine}
                stopDistance={stopDistance}
                setStopDistance={setStopDistance}
                missionData={lastMissionData}
                dialogError={dialogError}
                setDialogError={setDialogError}
            />
            <NewStatusDialog
                statusList={statusList}
                newStatusDialogOpen={newStatusDialogOpen}
                handleNewStatusDialogToggle={handleNewStatusDialogToggle}
                handleStatusChange={handleStatusChange}
            />
            <NewLogEntryDialog
                logTypeList={logTypeList}
                newLogEntryDialogOpen={newLogEntryDialogOpen}
                handleNewLogEntryDialogToggle={handleNewLogEntryDialogToggle}
                handleNewLogEntry={handleNewLogEntry}
                logEntry={logEntry}
                setLogEntry={setLogEntry}
                setLogEntryType={setLogEntryType}
            />
            {error && (
                <Alert
                    severity='warning'
                    onClose={() => {
                        setError(null);
                    }}
                >
                    {error}
                </Alert>
            )}
            <MissionDataExp
                missionData={lastMissionData}
                handleEndMissionDialogToggle={handleEndMissionDialogToggle}
            />
            <MissionLogExp
                missionData={lastMissionData}
                handleNewLogEntryDialogToggle={handleNewLogEntryDialogToggle}
                handleNewStatusDialogToggle={handleNewStatusDialogToggle}
                logEntries={logEntries}
            />
        </React.Fragment>
    );
};

export default CurrentMission;
