import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
} from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';

const StartNewMissionCard = ({
    startLoc,
    setStartLoc,
    startEngine,
    setStartEngine,
    missionCatList,
    setStartMissionCat,
    handleStartSubmission,
    warning,
}) => {
    return (
        <Card style={{ marginTop: 20 }}>
            <CardHeader title='Start nytt oppdrag' />
            <CardContent>
                <TextField
                    variant='outlined'
                    type='text'
                    fullWidth
                    value={startLoc}
                    onChange={(e) => setStartLoc(e.target.value)}
                    id='startLocation'
                    label='Oppstartslokasjon'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    variant='outlined'
                    type='number'
                    fullWidth
                    id='startEngineHours'
                    label='Motortimer ved start'
                    value={startEngine}
                    onChange={(e) => setStartEngine(e.target.value)}
                    style={{ marginBottom: 10 }}
                />
                <Autocomplete
                    id='missionCat'
                    options={missionCatList}
                    noOptionsText='Ingen treff'
                    getOptionLabel={(option) => option.name}
                    onChange={(e, val) => setStartMissionCat(val)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Oppdragstype'
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                {warning && <Alert severity='warning'>{warning}</Alert>}
                {!warning && (
                    <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        size='large'
                        onClick={handleStartSubmission}
                    >
                        Start oppdrag
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default StartNewMissionCard;
