import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Home from './pages/Home/Home';
import Checklist from './pages/Checklist/Checklist';
import Fuel from './pages/Fuel/Fuel';
import Mission from './pages/Mission/Mission';
import Weather from './pages/Weather/Weather';
import Login from './pages/Login/Login';
import NoMatch from './pages/NoMatch/NoMatch';
import Footer from './components/common/Footer';
import { AuthContext } from './helpers/context/auth-context';

import teal from '@material-ui/core/colors/teal';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: 70,
    },
    grow: {
        flexGrow: 1,
    },
}));
const theme = createMuiTheme({
    palette: {
        primary: teal,
        type: 'dark',
    },
});
function App() {
    const { token } = useContext(AuthContext);
    const classes = useStyles();
    const PrivateRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    token ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/innlogging',
                                state: { from: location },
                            }}
                        />
                    )
                }
            />
        );
    };
    return (
        <ThemeProvider theme={theme}>
            <Container
                maxWidth='lg'
                className={classes.container}
                disableGutters
            >
                <CssBaseline />
                <Router>
                    <Switch>
                        <PrivateRoute exact path='/'>
                            <Home />
                        </PrivateRoute>
                        <PrivateRoute path='/oppdrag'>
                            <Mission />
                        </PrivateRoute>
                        <PrivateRoute path='/vaer'>
                            <Weather />
                        </PrivateRoute>
                        <PrivateRoute path='/sjekkliste'>
                            <Checklist />
                        </PrivateRoute>
                        <PrivateRoute path='/drivstoff'>
                            <Fuel />
                        </PrivateRoute>
                        <Route path='/innlogging'>
                            <Login />
                        </Route>
                        <Route>
                            <NoMatch />
                        </Route>
                    </Switch>
                    <Footer />
                </Router>
            </Container>
        </ThemeProvider>
    );
}

export default App;
