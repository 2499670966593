import React from 'react';
import {
    TextField,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const NewLogEntryDialog = ({
    logTypeList,
    newLogEntryDialogOpen,
    handleNewLogEntryDialogToggle,
    handleNewLogEntry,
    logEntry,
    setLogEntry,
    setLogEntryType,
}) => {
    return (
        <Dialog
            id='logEntryDialog'
            open={newLogEntryDialogOpen}
            onClose={handleNewLogEntryDialogToggle}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Ny loggføring:</DialogTitle>
            <DialogContent>
                <TextField
                    id='logEntryInput'
                    variant='outlined'
                    label='Loggføring'
                    multiline
                    rows='4'
                    fullWidth
                    value={logEntry}
                    onChange={(e) => setLogEntry(e.target.value)}
                    style={{ marginBottom: 10 }}
                />
                <Autocomplete
                    id='logTypeInput'
                    options={logTypeList.filter((item) => {
                        return item.showInApp === 1;
                    })}
                    noOptionsText='Ingen treff'
                    onChange={(e, val) => setLogEntryType(val)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Loggtype'
                            variant='outlined'
                            style={{ marginBottom: 10 }}
                        />
                    )}
                />
                <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            size='large'
                            color='primary'
                            onClick={handleNewLogEntry}
                        >
                            Lagre
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            size='large'
                            color='secondary'
                            onClick={handleNewLogEntryDialogToggle}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default NewLogEntryDialog;
