import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const EndMissionDialog = ({
    endMissionDialogOpen,
    handleEndMissionDialogToggle,
    handleEndMission,
    stopLoc,
    setStopLoc,
    stopEngine,
    setStopEngine,
    stopDistance,
    setStopDistance,
    missionData,
    dialogError,
    setDialogError,
}) => {
    return (
        <Dialog
            id='endMissionDialog'
            open={endMissionDialogOpen}
            onClose={handleEndMissionDialogToggle}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Avslutt oppdrag</DialogTitle>
            <DialogContent>
                {dialogError && (
                    <Alert
                        style={{ marginBottom: 10 }}
                        severity='warning'
                        onClose={() => {
                            setDialogError(null);
                        }}
                    >
                        {dialogError}
                    </Alert>
                )}
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Lokasjon ved avslutning'
                    value={stopLoc}
                    onChange={(e) => {
                        setStopLoc(e.target.value);
                    }}
                    type='text'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    fullWidth
                    variant='outlined'
                    label={`Motortimer stopp(OBS, må være høyere enn eller lik ${missionData.startEngine}`}
                    value={stopEngine}
                    onChange={(e) => {
                        setStopEngine(e.target.value);
                    }}
                    type='number'
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Nautiske mil på tur'
                    type='number'
                    value={stopDistance}
                    onChange={(e) => {
                        setStopDistance(e.target.value);
                    }}
                    style={{ marginBottom: 10 }}
                />
                <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={handleEndMission}
                        >
                            Avslutt oppdrag
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={handleEndMissionDialogToggle}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default EndMissionDialog;
