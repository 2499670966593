import React, { useState, useEffect } from 'react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Grid,
    Paper,
    Divider,
    Button,
} from '@material-ui/core';
import {
    ExpandMore as ExpandMoreIcon,
    Remove as RemoveIcon,
    Add as AddIcon,
} from '@material-ui/icons';
import Axios from 'axios';
import moment from 'moment';

const MissionDataExp = ({ missionData, handleEndMissionDialogToggle }) => {
    const [crew, setCrew] = useState(0);
    const [pob, setPob] = useState(missionData.pax);
    useEffect(() => {
        if (pob !== missionData.pax) {
            const saveData = {
                pax: pob,
            };
            Axios.patch(`v1/mission/${missionData.id}`, saveData)
                .then((res) => {
                    let newMissionData = missionData;
                    newMissionData.pax = pob;
                    localStorage.setItem(
                        'lastMission',
                        btoa(JSON.stringify(newMissionData))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [pob, missionData]);
    useEffect(() => {
        let crewcount = 0;
        if (missionData.crew1Id) {
            crewcount++;
        }
        if (missionData.crew2Id) {
            crewcount++;
        }
        if (missionData.crew3Id) {
            crewcount++;
        }
        if (missionData.crew4Id) {
            crewcount++;
        }
        setCrew(crewcount);
    }, [
        missionData.crew1Id,
        missionData.crew2Id,
        missionData.crew3Id,
        missionData.crew4Id,
    ]);
    const handlePobInc = () => {
        setPob(pob + 1);
    };
    const handlePobDec = () => {
        if (pob >= 1) {
            setPob(pob - 1);
        }
    };
    return (
        <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='missionDataPanel'
                id='missionDataPanelHeader'
            >
                <Typography variant='h5'>
                    Oppdragsdata og handlinger:
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Paper elevation={0}>
                    <Grid
                        container
                        spacing={3}
                        style={{ border: '1px solid #1f1e1e' }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ border: '1px solid #1f1e1e' }}
                        >
                            <Typography
                                variant='button'
                                display='block'
                                gutterBottom
                            >
                                Båtfører: {missionData.crew1Name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ border: '1px solid #1f1e1e' }}
                        >
                            <Typography
                                variant='button'
                                display='block'
                                gutterBottom
                            >
                                Navigatør:{' '}
                                {missionData.crew2Name && missionData.crew2Name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ border: '1px solid #1f1e1e' }}
                        >
                            <Typography
                                variant='button'
                                display='block'
                                gutterBottom
                            >
                                Førstehjelper:{' '}
                                {missionData.crew3Name && missionData.crew3Name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ border: '1px solid #1f1e1e' }}
                        >
                            <Typography
                                variant='button'
                                display='block'
                                gutterBottom
                            >
                                Mannskap:{' '}
                                {missionData.crew4Name && missionData.crew4Name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ border: '1px solid #1f1e1e' }}
                        >
                            <Typography
                                variant='button'
                                display='block'
                                gutterBottom
                            >
                                Oppdragstype: {missionData.missionCatName}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ border: '1px solid #1f1e1e' }}
                        >
                            <Typography
                                variant='button'
                                display='block'
                                gutterBottom
                            >
                                Start:{' '}
                                {moment(missionData.startTime).format(
                                    'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    <Grid container space={3}>
                        <Grid item xs={12} sm={3}>
                            <Button
                                color='primary'
                                startIcon={<AddIcon fontSize='large' />}
                                fullWidth
                                variant='contained'
                                size='large'
                                onClick={handlePobInc}
                            >
                                Legg til
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant='h3'
                                gutterBottom
                                align='center'
                            >
                                POB: {crew} + {pob}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button
                                color='secondary'
                                startIcon={<RemoveIcon fontSize='large' />}
                                fullWidth
                                variant='contained'
                                size='large'
                                onClick={handlePobDec}
                            >
                                Fjerne
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={handleEndMissionDialogToggle}
                    >
                        Avslutt oppdrag
                    </Button>
                </Paper>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default MissionDataExp;
