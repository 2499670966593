import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Grid,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import moment from 'moment';

const MissionLogExp = ({
    handleNewLogEntryDialogToggle,
    handleNewStatusDialogToggle,
    logEntries,
    missionData,
}) => {
    return (
        <ExpansionPanel style={{ marginTop: 10 }} defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5'>Oppdragslogg:</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            size='large'
                            color='primary'
                            onClick={handleNewLogEntryDialogToggle}
                        >
                            Ny loggføring
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            size='large'
                            color='secondary'
                            onClick={handleNewStatusDialogToggle}
                        >
                            Ny status
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: 200 }}>
                                            Tid
                                        </TableCell>
                                        <TableCell style={{ width: 200 }}>
                                            Type
                                        </TableCell>
                                        <TableCell>Innhold</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logEntries.map((logEntry, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {moment(
                                                    logEntry.timestamp
                                                ).format(
                                                    'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {logEntry.category}
                                            </TableCell>
                                            <TableCell>
                                                {logEntry.remark}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default MissionLogExp;
