import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomeStatusCard from '../../components/Cards/HomeStatusCard';
import Loading from '../../components/common/Loading';

const Home = () => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const [lastMissionData, setLastMissionData] = useState({});

    useEffect(() => {
        setLoading(true);
        axios
            .get('v1/lastmission')
            .then((response) => {
                setLoading(false);
                localStorage.setItem(
                    'lastMission',
                    btoa(JSON.stringify(response.data[0]))
                );
                setLastMissionData(response.data[0]);
            })
            .catch((error) => {
                setLoading(false);
                setError(
                    'Kunne ikke laste data fra server.. Lokale data blir brukt...'
                );
                const localMissionData =
                    JSON.parse(atob(localStorage.getItem('lastMission'))) ||
                    null;
                setLastMissionData(localMissionData);
            });
    }, []);

    return (
        <React.Fragment>
            {loading && <Loading />}
            {!loading && (
                <HomeStatusCard
                    lastMissionData={lastMissionData}
                    error={error}
                    setError={setError}
                />
            )}
        </React.Fragment>
    );
};

export default Home;
